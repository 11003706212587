/* eslint-disable */
import React, { useState, useEffect, useLayoutEffect, Suspense, useRef } from 'react'

// YapContentBuilder components
import { 
         YapPage, 
         YapHeader,
         YapHeaderButton,
         YapNotification,
         YapContextMenu,
         YapContextMenuOption,
         useYapPageState,
         YapUI,
         SegmentedControl,
         JSONFormatter,
         InspectorStateProvider
       } from '@yapstudios/yap-content-builder'

import {
        getPanelElement,
        getPanelGroupElement,
        getResizeHandleElement,
        Panel,
        PanelGroup,
        PanelResizeHandle,
       } from "react-resizable-panels";
    

// Editor UI
import { CodeEditor } from './CodeEditor/CodeEditor.tsx'
import { Renderer } from './YapUI/Renderer/Renderer.js'
import { FileSidebar } from './FileSidebar.js';
import { Loading } from '../Loading.js'
import { EditorDetail , EditorViewActions} from "./EditorDetail.js"
import { ComponentGrid } from './ComponentGrid.js'
import { ComponentInfo } from './ComponentInfo.js';
import { Assets, AssetsDetail, AssetsActions, useAssets } from './Assets.js'
import { MenuContentShare, MenuContentPresent, MenuContentPreview } from './AppMenu.js';
import { menuAction, MenuMain } from './AppMenu.js'
import { Composer, ComposerViewActions, useComposer } from './Composer/Composer.js'
import { Projects } from './Projects.js'
import { ComposerInspector } from './Composer/ComposerInspector.js';
import { IssuesMenu } from './IssuesMenu.tsx';

import ASTUtil from './YapUI/YapUIASTUtil.js'
import { useJSRuntime } from './YapUI/useJSRuntime.js'

// Styling
import styled from 'styled-components'

// Data / Firebase
import { updateProjectData, useUserProjects, useProject, useProjectData, useUserState } from './Backend.js'

// React router
import { useNavigate, useLoaderData, useParams, Await } from "react-router-dom";

// Auth
import { FirebaseAuthProvider , useAuth } from './Auth.js';
import { ProtectedRoute } from "./ProtectedRoute.js"

// YapUI
import { YapUIDecoder } from './YapUI/YapUIDecoder.js'

// JSON5 Parsing
import JSON5 from 'json5'

// Util
import { v4 as uuidv4 } from 'uuid';
import { useSessionStorage, useLocalStorage } from 'usehooks-ts'
import pathutil from 'path-browserify';

// Key handling
import useKeyCombo from './Util/useKeyCombo.js'

// Default Content
import { newFileTemplate } from './FileTemplates.js';

import { useAppMirror } from '../AppMirror.js'
import { PathContext, PathBar } from './PathBar.tsx';
import { YapPreview } from '../YapPreview/YapPreview.js';
import { YapJSRuntime } from './YapUI/JSRuntime.js';

export const ProjectsRoute = (props) => {
    return (
        <FirebaseAuthProvider>
            <ProtectedRoute>
                <ProjectsLoader/>
             </ProtectedRoute>
        </FirebaseAuthProvider>
    );
};

export const ProjectsLoader = (props) => {  
    const auth = useAuth()
    if (auth == null) { return null }
    const userProjects = useUserProjects({ userId: auth.currentUser.uid })          
    return <Projects projects={userProjects}/>    
}

export const ProjectRoute = (props) => {
    const params = useParams();
    let routePath = params["*"]
    let project = params["project"]

    const extension = pathutil.extname(routePath);
    const pathWithoutExtension = routePath.replace(extension, '') 

    var loader = null
    if (extension == '.preview') {  
        loader = <PreviewLoader projectId={project} path={pathWithoutExtension}/>   
    } else if (extension == '.json') {  
        loader = <JSONLoader projectId={project} path={pathWithoutExtension}/>  
    } else {
        loader =<ProtectedRoute>
                    <ProjectLoader projectId={project} path={routePath}/>
                </ProtectedRoute>                 
    }

    return (
        <FirebaseAuthProvider>
            <ProtectedRoute>
                {loader}
             </ProtectedRoute>
        </FirebaseAuthProvider>
    );
};

export const ProjectLoader = ({ projectId, path }) => {  
    const auth = useAuth()
    if (projectId == null) { return "no project id" }  
    if (auth == null) { return "no auth" }

    const project = useProject({ projectId: projectId })    
    const projectData = useProjectData({ projectId: projectId })


    if (project && projectData) {
        return <ContentEditor path={path} project={project} data={projectData}/>    
    } else {
        return <Loading/>
    }
}

export const PreviewLoader = ({ projectId, path }) => {
    const project = useProject({ projectId: projectId })    
    const projectData = useProjectData({ projectId: projectId })

    if (project && projectData) {
        const screen = (projectData.types ?? []).find(t => t.path === path)  
        return <YapPreview screen={screen} components={projectData.allTypes} updateTime={projectData.updateTime}  projectId={project.id} canChangePreviews={true}/>
    } else {
        return null
    }
}

export const JSONLoader = ({ projectId, path }) => {
    const project = useProject({ projectId: projectId })    
    const projectData = useProjectData({ projectId: projectId })

    if (project && projectData) {
        const screen = (projectData.types ?? []).find(t => t.path === path)  
        return <JSONFormatter>{JSON5.parse(screen.json)}</JSONFormatter>
    } else {
        return null
    }
}

/**
 * View Model
 */

export const ItemType = {
    yapui: "component", 
    package: "package",
    folder: "folder",
    composer: "composer",
    assets: "assets",
    colors: "colors"
}

/**
 * Encodes component data stored in firebase from a yapui js content string
 */
export function encodeComponent(content, name, runtime) {
    
    // var body = null
    // try {
    //     const astObject = runtime.call(name, 'body');
    //     body = JSON5.stringify(astObject, null, 2)    
    // } catch (e) {
    //     console.log('Error generating body', e)
    // }

    var previews = []
    try {
        const previewAST = runtime.call(name, 'previews');
        if (Array.isArray(previewAST)) {
            previews = previewAST
        } else {
            previews = [previewAST]
        }
    } catch (e) {
        console.log('Error generating previews', e)
    }

    // var thumbnail = null
    // try {
    //     const thumbnailAST = runtime.call(name, 'thumbnail');
    //     thumbnail = JSON5.stringify(thumbnailAST, null, 2)  
    //     console.log('Thumbnail', thumbnail) 
    // } catch (e) {   
    //     console.log('Error generating thumbnail', e)
    // }

    var metadata = null
    try {
        metadata = runtime.call(name, 'component', { name: name});
    } catch (e) {
        console.log('Error generating metadata', e)
    }

    var inspector = null
    try {
        let inspectorProperties = runtime.call(name, 'inspector', { name: name});
        let obj = { properties: inspectorProperties}
        inspector = JSON.stringify(obj, null, 2)  
        console.log(inspector)

    } catch (e) {
        //console.log('Error generating metadata', e)
    }

    //console.log('AST:' , body)                

    // Construct 
    var data = { previews: previews.map((preview, idx) => {
                    let modifier = ASTUtil.findModifier(preview, 'previewName', true)
                    let title = modifier?.props.rawValue ?? modifier?.props.value    
                    let p = { index: idx}
                    if (title) {
                        p.title = title
                    }
                    return p
                }),
                metadata: metadata,
                inspector: inspector
              }

    // delete undefined keys in data recursively
    Object.keys(data).forEach(key => (data[key] === undefined || data[key == null]) && delete data[key])

    return data
}

function useContentEditor({ path, project, data }) {
    const defaultTypeItems = [ ]

    const navigate = useNavigate()

    var selectedId = path

    /**
     * Items
     */
    const [items, setItems] = useState(data.types ?? [])
    const itemsRef = React.useRef(null)    
    itemsRef.current = items

    useEffect(() => {   
        setItems(data.types ?? [])
    }, [data.types])

    /**
     * 'All items' which may include other types we're not saving, such as external depenancies.
     */
    const allItems = [...data.packageTypes, ...items];
    
    const setSelectedId = (id) => {
        if (id) {
            navigate(`/designer/${project.id}/${id}`)
        } else {
            navigate(`/designer/${project.id}`)
        }
    }

    // Rewrite for .assets child selection
    // selectedId is the main item
    // selectedChildId is the child path in the assets structure.
    const selectedPath = selectedId
    if (selectedId != null && String(selectedId).indexOf('.assets/') > -1) {
        let items = String(selectedId).split('.assets/')
        selectedId = items[0] + '.assets'   
    }

    // Path setter
    const setSelectedPath = (path) => {
        setSelectedId(path)
    }

    // Selected Item
    const selectedItem = selectedId != null ? items.find((item) => item.path === selectedId) : null

    const isComposerFrame = selectedPath.startsWith('ComposerFrames/') || selectedItem?.metadata?.composerFrame == true

    // Incremented when there's a change to the js content
    const [contentVersion, setContentVersion] = useState(0)

    // UI Publish Notification
    const [showPublishNotification, setShowPublishNotification ] = React.useState(false);

    // Preview States
    const previewStates = selectedItem ? selectedItem.previews ?? [] : []
    const [previewIndex, setPreviewIndex] = React.useState(null);

    // Frame
    const frameIdenfifier = (selectedItem && selectedItem.frame) ? selectedItem.frame : null

    // Source type
    const sourceType = extractViewType(selectedItem ? selectedItem.path : null)

    // View Name
    const viewName = extractViewName(selectedItem ? selectedItem.path : null, false)

    // Preview modal
    const [showPreviewModal, setShowPreviewModal] = useState(false)

    const defaultFrame = allItems.find((item) => item.name === 'DefaultFrame')

    // App Mirror
    const [mirrorSessionId, setMirrorSessionId] = useLocalStorage("mirrorSessionId", null);

    const mirror = useAppMirror(mirrorSessionId)

    const [uiMode, setUIMode] = useUserState('uiMode', 'design-right')

    const [previewDisplayType, setPreviewDisplayType] = useState("preview")

    const [codeEditorType, setCodeEditorType] = useState("ui")

    const runtime = useJSRuntime(selectedId)

    const saveTimer = useRef(null)
    //.console.log('Runtime', runtime) 

    // Get packages
    const packages = data.packages ?? []
    //console.log('Packages', packages)

    const [codeEditorIssues, setCodeEditorIssues ] = useState([])
    const [runtimeIssues, setRuntimeIssues ] = useState([])

    const startMirrorSession = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let code = '';
        let length = 6;
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            code += characters[randomIndex];
        }
          
        setMirrorSessionId(code)
    }

    const stopMirrorSession = () => {
        setMirrorSessionId(null)
    }

    const onPathBarChanged = (path) => {  
        const newItem = items.find((item) => item.path == path)
        if (newItem && newItem.type == ItemType.folder) {   
            setSelectedId(path + '/')   
        } else {
            setSelectedId(path)
        }
    }

    /**
     * Determine type. TODO: migrate to property
     */
    var selectedItemType = null
    if (selectedId) {
        if (selectedId.startsWith('Package')) {
            selectedItemType = ItemType.package
        } else if (selectedId.endsWith('/') || (selectedItem?.type == ItemType.folder)) {
            selectedItemType = ItemType.folder
        } else if (selectedId.endsWith('.composer') || selectedItem?.type == ItemType.composer) {
            selectedItemType = ItemType.composer    
        } else if (selectedId.endsWith('.assets') || selectedItem?.type == ItemType.assets) {
            selectedItemType = ItemType.assets
        } else if (selectedId.endsWith('.colors') || selectedItem?.type == ItemType.colors) {
            selectedItemType = ItemType.colors
        } else {
            selectedItemType = ItemType.yapui
        }
    }

    //console.log('Selected Item', selectedItem, selectedId, selectedItemType)

    const mirrorSyncSelected = (item) => {
        if (item && selectedItemType == ItemType.yapui) {   
            try {
                let data = {...item}
                delete data['jsonContent']
                delete data['states']
                delete data['title']
                
                // delete undefined keys
                Object.keys(data).forEach(key => data[key] === undefined && delete data[key])   

                mirror.syncSelected(data)
            } catch (e) {
                console.error(e)
            }            
        }
    }

    const mirrorSyncComponents = () => {    
        mirror.syncComponents(items.filter( (item) => item.json != null).map((item) => {
            return item
        }))
    }

    React.useEffect(() => {
        setPreviewIndex(0)
        setCodeEditorIssues([])
    }, [selectedId])

    React.useEffect(() => {
        mirror.syncFrame(getLastPart(frameIdenfifier))
    }, [frameIdenfifier])

    React.useEffect(() => { 
        mirrorSyncSelected(selectedItem)
        mirrorSyncComponents()
        mirror.syncPreview(previewIndex)
        
    }, [selectedId, mirrorSessionId])

    React.useEffect(() => { 
        mirror.syncPreview(previewIndex)
    }, [previewIndex])


    const registerComponentBody = (componentName, content) => {  
        runtime.registerComponent(componentName, content)
    }

    const registerComposerThumbnail = (componentName, content) => {  
        runtime.registerComponent(componentName + '_thumbnail', content, 'thumbnail')   
    }

    /**
     * Register views with the runtime when the project is loaded
     */
    useEffect(() => {   
        if (runtime == null) { 
            console.warn('no runtime')
            return 
        } 

        console.log('Registering views ', data.types.length, project)

        // Reset runtime
        runtime.reset()

        // Register component bodies
        const allItems = [...data.packageTypes, ...data.types]
        allItems.filter(item => item.type == ItemType.yapui || item.type == ItemType.composer).forEach((item) => {
            registerComponentBody(item.name, item.content)  
        })

        // Register composer thumbnails
        let composerFrameThumbnails = {}
        allItems.filter(item => item.type == ItemType.yapui && item.metadata?.composerFrame == true).forEach((item) => {
            registerComposerThumbnail(item.name, item.content)  
        })

        console.log('register composer thumbnails', composerFrameThumbnails)    

    }, [data.projectId])

    /**
     * Callback when code editor content is updated
     */
    const updateContent = (content) => {

        var item = {...selectedItem}
        
        item.content = content

        // Reset runtime when updating component source
        runtime.resetStorage()
        runtime.resetState()
        
        // Update body
        registerComponentBody(item.name, content)

        // Update thumbnail record if this is a composer frame
        if (isComposerFrame) {  
            registerComposerThumbnail(item.name, content)
        }
        
        var component  = item
        try {
            component = encodeComponent(item.content, item.name, runtime);
        } catch (e) {
            console.error('encodeComponent: error: ', e, item.content)
            return
        }

        if (component) {
            item = {
                type: 'component',
                ...item, 
                ...component
            }                
        }

        updateItem(item)
        //mirrorSyncSelected(item)
        setContentVersion(contentVersion + 1)
        
        scheduleSave()
    }

    const scheduleSave = () => {
        saveTimer.current && clearTimeout(saveTimer.current)    

        if (selectedItem) {
            // Save every 5 seconds
            saveTimer.current = setTimeout(() => {
                console.log('+ Auto saving')
                saveContent({ showNotification: false })    
            }, 1500)
        }
    }

    /**
     * Updates an existing item 
     */
    const updateItem = (item) => {
        let newItems = items.map((existingItem) => {
            if (existingItem.path === selectedId) {
                return {...item}
            }
            return existingItem
        })

        const updated = [...newItems]
        setItems(updated)
        itemsRef.current = updated
        console.log('setItems (updateItem)', newItems)
    }

    /**
     * Called when JSON is updated directly
     */
    const updateJSON = (json) => {  
        var item = {...selectedItem}
        try {
            const jsonObject = JSON5.parse(json)
            item.json = JSON.stringify(jsonObject, null, 2)
            let newItems = items.map((existingItem) => {
                if (existingItem.path === selectedId) {
                    return {...item}
                }
                return existingItem
            })
            setItems(newItems)
        } catch (e) {
            console.error('updateJSON', e)
        }
    }

    const saveContent = ({ showNotification = true }) => {
        //console.log('saveContent ', items)
        const items = itemsRef.current  
        console.log('saveContent ', items.filter((item) => item.name == viewName))  
        
        //console.log('saveContent ', itemsRef.current.filter((item) => item.name == 'HomeScreenHeader'))  
        updateProjectData(project.id, { types: items  }) 
        if (showNotification) {
            setShowPublishNotification(true)
        }
    }

    const newFilename = (name, parentPath, extension, depth) => {
        let currentName = name + (depth > 0 ? depth : '')        
        let fullPath = (parentPath ? parentPath + currentName : currentName) + (extension ? `.${extension}` : '')

        for (var item of items) {
            if (item.path == fullPath) {
                return newFilename(name , parentPath, extension, depth + 1)
            }
        }

        return fullPath
    }

    const addNewItem = (parentPath, type, propsCallback) => {
        let userInput = prompt("Name");
        if (!(userInput && userInput.length > 0)) {
            return;
        }

        // Get view name from full path
        let name = extractViewName(userInput, false)

        // Generate new file template        
        let extraProps = {} 
        if (propsCallback) {
            extraProps = propsCallback(name)
        }

        let finalPath = newFilename(name, parentPath, null, 0)  

        let newItem = { name: extractViewName(finalPath, false), 
                        path: finalPath,
                        type: type,
                        id: uuidv4(),             
                        ...extraProps
                    }

        // Update and select
        setItems([...items, newItem])
        setSelectedId(newItem.path)
        saveContent({ showNotification: false })
    }

    const addNewComponent = (parentPath) => {
        addNewItem(parentPath, ItemType.yapui, (name) => {
            let template = newFileTemplate({ name: name })
            let component = encodeComponent(template, name, runtime)
            registerComponentBody(name, template)
            return { content: template, ...component }
        })  
    }

    const addNewComposer = (parentPath) => {
        addNewItem(parentPath, ItemType.composer, (name) => {
            return {
                composerFrame: 'ComposerFrame'
            }
        })  
    }

    const addNewFolder = (parentPath) => {
        let userInput = prompt("Folder Name");

        if (!(userInput && userInput.length > 0)) {
            return;
        }

        // Get view name from full path
        let name = extractViewName(userInput, false)

        // Construct 
        let newItem = { path: parentPath ? parentPath + userInput : userInput, 
            name: name, 
            title: userInput,  // deprecate
            id: uuidv4(), 
            type: 'folder',
            isFolder: true }

        // Update and select
        setItems([...items, newItem])
        setSelectedId(newItem.path + '/')
        saveContent({ showNotification: false })
    }

    const addNewAssets = (parentPath) => {
        //let userInput = prompt("Assets Name");
        let name = 'Assets'

        // Construct 
        let newItem = { path: newFilename(name, parentPath, 'assets', 0),
            name: name, 
            title: name,  // deprecate
            id: uuidv4(), 
            type: 'assets' }

        // Update and select
        setItems([...items, newItem])
        setSelectedId(newItem.path)
        saveContent({ showNotification: false })
    }

    const onDelete = (id) => {
        let isFolder = id.endsWith('/')
        let folderWithoutEndSlash = isFolder ? id.slice(0, -1) : id
        
        // Show a confirmation alert
        //let item = items.find((item) => (item.path == id || (isFolder && item.path == folderWithoutEndSlash)))

        if (!window.confirm(`Delete ${id} ?`)) {
            return
        }
        let newItems = items.filter((item) => {
            if (isFolder) {
                return !item.path.startsWith(folderWithoutEndSlash)
            } else {
                return item.path !== id
            }
        })
        setItems(newItems)
        setSelectedId(null)
        saveContent({ showNotification: false })
    }

    const onRename = (id, path) => {

        let isFolder = id.endsWith('/')
        let folderWithoutEndSlash = isFolder ? id.slice(0, -1) : id
        
        let newItems = items.map((item) => {
            if (isFolder && item.path.startsWith(folderWithoutEndSlash)) { 
                let itemPath = item.path.replace(folderWithoutEndSlash, path)
                item = {...item,
                    title: itemPath,
                    path: itemPath
                }
                setSelectedId(path + '/')
            } else if (item.path == id) {
                let name = extractViewName(path, false)
                item = {...item, 
                        title: path, 
                        path: path, 
                        name: name }
                setSelectedId(path)
            }
            return item
        })
        setItems(newItems)
        saveContent({ showNotification: false })
    }

    const findItem = (name) => {    
        return findView(name, allItems) 
    }

    /**
     * Callback that preserves the case of the name
     */
    const nameCallback = (tagName) => {
        for (const item of items) {
            const parts = (item.name ?? item.title).split('/')
            const itemName = String(parts[parts.length - 1])
            if (itemName.toLowerCase() === tagName.toLowerCase()) {
                return itemName
            }
        }
        return null
    }

    /**
     * Callback that resolves jsx for a specific view name
     */
    const viewCallback = (name, props, viewCallback) => {
        return null
        //return viewForComponentName(name, allItems, viewCallback)
    }

     /**
     * Callback that provides default values 
     */
    const defaultsCallback = (name) => {
        return {}
        //return inspectorDefaultsForComponentName(name, allItems)
    }

    const routeCallback = (name) => {
        //console.log('route', name)

        if (name == '#back' && window.location.href.indexOf('#ui=') > -1) {
            //setUserPreviewIndex(0)
            navigate(-1)
            return
        }


        // JSON
        if (typeof name == 'object') {  
            let encode = JSON5.stringify(name)
            // encode to base 64
            let base64 = btoa(encode)
            navigate(`#ui=${base64}`)
            return encode
        }
        //const view = findView(name, allItems) 
        //if (view) {
        //    alert(view.path)
        //}
    }

    const jsCallback = (name) => {
        const file = findView(name + ".js", allItems)
        if (file) {
            return file.content
        } else {
            return null
        }
    }

    /**
     * Assets
     */
    const getAsset = (key, componentName, parentNames) => {    
        
        //console.log('Get Asset', key, componentName, parentNames)

        // Test current folder if selected
        if (selectedPath.endsWith('/')) {
            let p = selectedPath.slice(0, selectedPath.length - 1)
            let current = assetForKey(key, p, allItems)  
            if (current) { return current }
        }
        for (var name of parentNames) { 
            let asset = assetForKey(key, name, allItems)  
            if (asset) { return asset }
        }
        return null
    }

    return { updateContent, viewCallback , defaultsCallback, routeCallback, nameCallback, onRename, onDelete, saveContent, selectedId, setSelectedId,selectedItem, frameIdenfifier, 
        previewStates, selectedItem, previewIndex, setPreviewIndex, items, setItems, allItems, defaultFrame, selectedItem, 
        contentVersion, updateJSON, contentVersion, showPublishNotification, setShowPublishNotification, 
        // showAssets, setShowAssets,
        showPreviewModal, setShowPreviewModal, jsCallback,
        selectedItemType, 
        addNewComponent, addNewFolder, addNewAssets, addNewComposer,
        mirrorSessionId, setMirrorSessionId, startMirrorSession, stopMirrorSession,
        updateItem, findItem,
        selectedPath, setSelectedPath, onPathBarChanged,
        getAsset, project, packages,
        uiMode, setUIMode,
        previewDisplayType, setPreviewDisplayType,  
        isComposerFrame,
        codeEditorType, setCodeEditorType,
        setRuntimeIssues, runtimeIssues,
        setCodeEditorIssues, codeEditorIssues,
        runtime
    }    
}


function getLastPart(path) {
    if (path == null) { return null }   
    // Split the path by '/' and return the last element
    const parts = path.split('/');
    return parts[parts.length - 1];
}

/**
 * Key Handler
 */
function useEditorKeyCombos({ previewStates, setPreviewIndex, setShowPreviewModal, saveContent}) {
    /**
   * Keycombos
   */

  // Previews
  const previewKeys = previewStates.reduce((acc, state, idx) => {
      acc[`Meta+Shift+Digit${idx + 1}`] = () => setPreviewIndex(idx);
      return acc;
  }, {});

  
  // System
  useKeyCombo({
       'Meta+S' : saveContent,
       'Meta+Shift+F' : () => { setShowPreviewModal(true) },
      ...previewKeys })
}

export const useLinkUI = () => {
    let path = window.location.href
    let comps = path.split('#ui=')
    if (comps.length > 1) { 
        try {
            let base64 = comps[1]
            let decode = atob(base64)
            return decode
        } catch (e) {

        }
    } else {
        return null 
    }
}

/**
 * View
 */
export function ContentEditor({ path, project, data }) {
    
    // Editor View model
    const editor = useContentEditor({ path: path, project: project, data: data }) 
    
    const auth = useAuth()
    const userProjects = useUserProjects({ userId: auth.currentUser.uid })      
    const layoutSizeRef = React.useRef(10)


    // Detect yapui in url
    let linkUI = useLinkUI()

    /**
     * Get view model properties
     */
    const { selectedId, selectedPath, setSelectedPath, selectedItem, 
            items, 
            updateContent, updateItem, viewCallback, defaultsCallback, routeCallback, jsCallback, getAsset, findItem,
            addNew, onRename, onDelete, saveContent, updateJSON,
            frameIdenfifier, 
            setPreviewIndex, previewIndex, contentVersion,
            previewStates, 
            showPublishNotification, setShowPublishNotification, 
            setShowPreviewModal,
            selectedItemType, 
            allItems, packages,
            uiMode, setUIMode,
            previewDisplayType,
            isComposerFrame,
            codeEditorType, setCodeEditorType,
            runtime, 
            setCurrentItemErrors, currentItemErrors,
            setCodeEditorIssues, codeEditorIssues,
            startMirrorSession, stopMirrorSession } = editor

    // Assets view model
    const assets = useAssets({ setSelectedPath: setSelectedPath, 
                              selectedPath: selectedPath, assets: selectedItem?.type == ItemType.assets ? selectedItem.assetSets ?? [] : [],
                              setAssets: (assets) => {
        updateItem({ ...selectedItem, assetSets: assets })
    }})    

    // Composer View Model
    const componentItems =  items.filter(item => item.type == ItemType.yapui).filter((componentType) => {
        if (componentType.metadata && componentType.metadata.component) { 
            if (componentType.metadata.component.public) {
                return true
            }
            if (componentType.metadata.component == 'public') {
                return true
            }   
        }
        return false
        return (componentType.metadata && componentType.metadata.component == 'public') 
    })

    const composerItem = selectedItemType == ItemType.composer ? selectedItem : null
    //console.log('Composer Item', composerItem)  
    const composer = composerItem ? useComposer({ item: composerItem, 
                                                  projectId: project.id,
                                                  updateItem: updateItem, 
                                                  componentItems: componentItems, 
                                                  viewCallback: viewCallback,
                                                  itemCallback: findItem,
                                                  assetCallback: getAsset,
                                                  defaultsCallback: defaultsCallback,
                                                  runtime: runtime,
                                                  allItems: allItems}) : useComposer({ componentTypes: [] })

    // Handle keys
    useEditorKeyCombos({ previewStates: previewStates,  
                         saveContent: saveContent,
                         setPreviewIndex: setPreviewIndex, 
                         setShowPreviewModal: setShowPreviewModal })

    // Top level actions
    const actions = [
        <YapHeaderButton key="mirror" content={<MenuContentPresent editor={editor}/>}>{"Mirror"}</YapHeaderButton>,

        <YapHeaderButton key="save" onClick={() => {
            saveContent()
        }}>{"Publish"}</YapHeaderButton>

    ]

    /**
     * Sidebar
     */
    const fileSidebar = <FileSidebar editor={editor} />

    /**
     * Setup Environment
     */
    var environment = {
        'preview' : 'design',
        'screen'  : 'desktop'
    }

    var rendererPreviewFrame = frameIdenfifier

    if (isComposerFrame) {
        if (previewDisplayType == 'composer') {  
            environment['environment.preview'] = 'design'
            environment['preview'] = 'design'
            rendererPreviewFrame = null
        } else {
            environment['environment.preview'] = 'view'
            environment['preview'] = 'view'
        }
    }

    /**
     *  Renderer
     */
    const yapui = selectedItem ? selectedItem.json : null
    const renderer = <Renderer componentName={selectedItem ? selectedItem.name : null}
                               getAsset={editor.getAsset}
                               errorsCallback={setCurrentItemErrors} 
                               routeCallback={routeCallback}
                               viewCallback={viewCallback} 
                               defaultsCallback={defaultsCallback}
                               jsCallback={jsCallback}
                               frame={rendererPreviewFrame} 
                               previews={previewStates}
                               previewIndex={previewIndex} 
                               ignorePreview={linkUI}
                               environment={environment}    
                               runtime={runtime}
                               allowDefaultFrame={selectedItem?.path.startsWith('PreviewFrame') == false}/>

    /**
     * JSON Preview
     */
    const astPreview =  <JSONPreview setJSON={updateJSON} contentVersion={contentVersion} path={selectedItem ? selectedItem.path ?? selectedItem.title : null} json={selectedItem ? selectedItem.json : null}/>
    const jsPreview  =  <JSPreview contentVersion={contentVersion} path={selectedItem ? selectedItem.path ?? selectedItem.title : null} js={selectedItem ? selectedItem.content : null}/>

    /**
     * Component Info
     */
    const componentInfo = selectedItem?.metadata ? <ComponentInfo component={selectedItem}  /> : null

    /**
     * Inspector
     */
    const inspectorPreview = selectedItem?.inspector ? 
                                <InspectorPreview  name={selectedItem?.metadata?.name ?? selectedItem?.name}  
                                                model={{}} updateModel={() => { }} inspector={selectedItem.inspector}/> : null
    /**
     * Folder Content
     */
    const folderContent = <ComponentGrid editor={editor} items={
        items
            .filter(item => (item.type == ItemType.yapui || item.type == ItemType.composer) )
            .filter( item => { return selectedPath ? item.path.startsWith(selectedPath) : true })
    }/> 

    var centerContent = null
    var detailContent = null
    var fileActions = []
    var viewMenu = null

    /**
     * Mode menu
     */
    var menuTitle = "Design"
    switch (codeEditorType) {
        case 'metadata':
            menuTitle = "Test"
            break;
        case 'inspector':
            menuTitle = "Use"
            break;
        default:
            menuTitle = "Design"
            break;
    }

    var modeMenu = (
        <YapContextMenu align="left" action={<YapHeaderButton>{menuTitle}</YapHeaderButton>}>
            <YapContextMenuOption onClick={() => { setCodeEditorType('ui') }} title="Design" key={["cmd", "control", "c"]} checked={codeEditorType == 'ui'} />
            <YapContextMenuOption onClick={() => { setCodeEditorType('metadata') }} title="Test"  key={["cmd", "control", "v"]} checked={codeEditorType == 'metadata'} />
            <YapContextMenuOption onClick={() => { setCodeEditorType('inspector') }} title="Use"  key={["cmd", "control", "t"]} checked={codeEditorType == 'inspector'} />
        </YapContextMenu>
    )

    var issuesMenu = <IssuesMenu issues={codeEditorIssues} />

    switch (selectedItemType) {
        case ItemType.folder:
            centerContent = folderContent
            detailContent = null;
            modeMenu = null;
            break

        case ItemType.package:
            let id = selectedId.split('/')[1]
            let pkg = packages.find((item) => item.id == id)
            centerContent = pkg ? <ComponentGrid editor={editor} items={pkg.publicTypes}/> : null;
            detailContent = null;   
            break

        /**
         * Code Eidtor
         */            
        case ItemType.yapui:    
            var codeEditorContent = ""
            var codeEditorPath = (selectedItem?.path ?? selectedItem?.title) ?? ""
            var editorContent = <EditorDetail  optionsBottom={true} 
                                               astPreview={astPreview} 
                                               jsPreview={jsPreview} 
                                               editor={editor} 
                                               renderer={renderer} 
                                               componentInfo={componentInfo}
                                               inspectorPreview={inspectorPreview} /> 

            codeEditorContent = selectedItem?.content ?? ""
            
            const codeEditorLanguage = "javascript"
            var codeEditorViewMenu = <EditorViewActions editor={editor}/>

            const codeEditor =  <CodeEditor titleMenu={issuesMenu} setIssues={setCodeEditorIssues} header={true} codeEditorType={codeEditorType} setCodeEditorType={setCodeEditorType} path={codeEditorPath} language={codeEditorLanguage} content={codeEditorContent} setContent={updateContent} viewMenu={codeEditorViewMenu}/>
            centerContent = editorContent
            detailContent = codeEditor    

            if (uiMode == 'view') {
                detailContent = null
                viewMenu =  <EditorViewActions editor={editor}/>
            }

            break

        /**
         * Composer
         */
        case ItemType.composer:
            centerContent = <Composer composer={composer}/>;
            viewMenu      = <ComposerViewActions composer={composer}/>
            detailContent = null;
            modeMenu      = null;
            break

        /**
         * Assets
         */
        
        case ItemType.assets:
            fileActions   = AssetsActions({ assets: assets })
            detailContent = <AssetsDetail assets={assets} />;
            centerContent = <Assets assets={assets}  />;
            modeMenu      = null;
            break

        default:
            centerContent = folderContent
            detailContent = null
            modeMenu      = null;
            break

    }

    const mainMenuAction = menuAction({ projects: userProjects, projectId: project.id, project: project })

    const rightContent = detailContent ? (
        <>
            <PanelResizeHandle className="resize-handle" id="resize-handle-right" />

            <Panel id={"right-panel" } defaultSize={layoutSizeRef.current} minSize={20}>
                <section className="layout-center" >
                    {detailContent}
                </section>
            </Panel>
        </>
    ) : null

    const onLayout = (sizes) => {
        layoutSizeRef.current = sizes[2]
    }

    const shareIcon = (
        <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.64062 19.6875C3.77604 19.6875 3.11719 19.4635 2.66406 19.0156C2.21615 18.5729 1.99219 17.9219 1.99219 17.0625V9.45312C1.99219 8.59375 2.21615 7.94271 2.66406 7.5C3.11719 7.05208 3.77604 6.82812 4.64062 6.82812H6.84375V8.57031H4.78125C4.4375 8.57031 4.17448 8.65885 3.99219 8.83594C3.8151 9.00781 3.72656 9.27344 3.72656 9.63281V16.8828C3.72656 17.2422 3.8151 17.5104 3.99219 17.6875C4.17448 17.8646 4.4375 17.9531 4.78125 17.9531H13.5078C13.8464 17.9531 14.1068 17.8646 14.2891 17.6875C14.4714 17.5104 14.5625 17.2422 14.5625 16.8828V9.63281C14.5625 9.27344 14.4714 9.00781 14.2891 8.83594C14.1068 8.65885 13.8464 8.57031 13.5078 8.57031H11.4531V6.82812H13.6562C14.5208 6.82812 15.1771 7.05208 15.625 7.5C16.0781 7.94792 16.3047 8.59896 16.3047 9.45312V17.0625C16.3047 17.9167 16.0781 18.5677 15.625 19.0156C15.1771 19.4635 14.5208 19.6875 13.6562 19.6875H4.64062ZM9.14062 13.3828C8.92188 13.3828 8.73438 13.3047 8.57812 13.1484C8.42188 12.9922 8.34375 12.8073 8.34375 12.5938V4.82812L8.41406 3.67969L7.96094 4.26562L6.92969 5.36719C6.78906 5.51823 6.61198 5.59375 6.39844 5.59375C6.20573 5.59375 6.03906 5.53125 5.89844 5.40625C5.76302 5.27604 5.69531 5.11198 5.69531 4.91406C5.69531 4.73177 5.76823 4.5651 5.91406 4.41406L8.52344 1.90625C8.63281 1.80208 8.73698 1.73177 8.83594 1.69531C8.9349 1.65365 9.03646 1.63281 9.14062 1.63281C9.25 1.63281 9.35417 1.65365 9.45312 1.69531C9.55729 1.73177 9.66146 1.80208 9.76562 1.90625L12.375 4.41406C12.5208 4.5651 12.5938 4.73177 12.5938 4.91406C12.5938 5.11198 12.5234 5.27604 12.3828 5.40625C12.2422 5.53125 12.0781 5.59375 11.8906 5.59375C11.6823 5.59375 11.5078 5.51823 11.3672 5.36719L10.3281 4.26562L9.88281 3.67969L9.94531 4.82812V12.5938C9.94531 12.8073 9.86719 12.9922 9.71094 13.1484C9.5599 13.3047 9.36979 13.3828 9.14062 13.3828Z" fill="#374DC0"/>
        </svg>
    )

    const shareMenu = (
        <YapContextMenu action={<YapHeaderButton>{shareIcon}</YapHeaderButton>}>
            <MenuContentPreview editor={editor}/>
        </YapContextMenu>
    )


    fileActions = fileActions.concat([
        
        shareMenu,
        viewMenu
    ])


    return (
        <YapPage fullscreen={true} >
            <YapNotification title="Published" icon={"success"} show={showPublishNotification} setShow={setShowPublishNotification}/>
            {/* <YapHeader fixed={true} titleLeft={true} largeTitle={false} title={project.name ?? "Designer"} headerActions={actions} headerActionsLeft={[mainMenuAction]}></YapHeader> */}

            <div className={`layout layout-app`}>
                <PanelGroup direction="horizontal" id={"designer-main-panel-group"} style={{height: 'calc(100vh)'}} onLayout={onLayout} >

                    <Panel id="left-panel" defaultSize={15} minSize={15}>
                        <SidebarContainer>
                            <YapHeader className="sidebar-header" title={project.name ?? "Designer"} headerActionsLeft={[mainMenuAction]} titleLeft={true} largeTitle={false}/>
                            {fileSidebar}
                        </SidebarContainer>
                    </Panel>

                    <PanelResizeHandle className="resize-handle" id="resize-handle-left" />

                    <Panel id="center-panel" >
                        <section className="layout-center" >
                            <PathContext.Provider value={{ path: selectedPath, root: project.id ?? "Root" }}>                                
                                <YapHeader className="file-header" titleLeft={false} largeTitle={false} headerActions={fileActions} headerActionsLeft={modeMenu} title={selectedItem?.name ?? ""} titlee={<PathBar onChange={editor.onPathBarChanged}/>}></YapHeader>   
                                <div className="layout-center-content">                            
                                    {centerContent}
                                </div>
                            </PathContext.Provider>
                        </section>
                    </Panel>

                    {rightContent}
                    
                </PanelGroup>
            </div>

        </YapPage>
    )
}


const CenterBar =  styled.div`
    display: flex;  
    align-items: center;
    flex-direction: row;
    .path-bar { 
        border-left: 1px solid #e0e0e0;
        padding-left: 24px;
    }
    .polymer-menu-action {
        margin-left: -12px;
        margin-right: 12px;
    }
`

const SidebarContainer = styled.div`   
    .polymer-header-content {
        padding-left: 50px;
    }
    min-width: 300px;
    // overflow: hidden;
`

function InspectorPreview(props) { 
     /** Decode inspector JSON */
     var inspector = {}
     try {
         if (props.inspector) {
             inspector = JSON.parse(props.inspector)  
         } else {
             console.log('no inspector')
         }
     } catch(e) { 
         //console.error('Error decoding inspector', e, this.inspector)    
     }

     console.log('Inspector', inspector, props.inspector)

    return (
        <InspectorContainer>
            <InspectorContent className="inspector">
                <InspectorStateProvider model={{}}>
                    <ComposerInspector {...props} inspector={inspector}/>
                </InspectorStateProvider>
            </InspectorContent>
        </InspectorContainer>
    )
}

const InspectorContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fafafa;
    height: -webkit-fill-available;
`

const InspectorContent = styled.div`
    width: 320px;
    background-color: white;
    padding: 20px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
    border-radius: 12px;
`

function JSONPreview({ contentVersion, setJSON, path, json }) {    
    return (
        <CodeEditor content={json} path={path + '/' + contentVersion} language={"json5"} setContent={setJSON}/>
    )
}

function JSPreview({ contentVersion, setJSON, path, js }) {    
    return (
        <CodeEditor content={js} path={path + '/' + contentVersion} language={"javascript"} setContent={() => { }}/>
    )
}

const extractViewName = (title, lowercase = true) => {
    if (title == null) { return null }
    const parts = String(title).split('/')
    var itemName = String(parts[parts.length - 1])

    const extensionParts = itemName.split('.')
    itemName = extensionParts[0]

    if (lowercase) {
        itemName = itemName.toLowerCase();
    } 

    return itemName;
}

const extractViewPath = (path) => {
    if (path == null) { return null }
    const parts = String(path).split('/')
    return parts.splice(0, parts.length - 1).join('/')
}

const extractViewType = (title) => {
    if (title == null) { return null }
    const extensionParts = String(title).split('.')
    return extensionParts[1] ?? "js"
}

export function viewForComponentName (name, items, viewCallback) {
    let view = findView(name, items)

    if (!(view && view.json)) {
        //console.log(`viewForComponentName: not found ${name}`);
        return null;
    }    

    try {
        let content = JSON5.parse(view.json);

        return YapUIDecoder(content, viewCallback ?? ((name) => {
            return viewForComponentName(name, items)
        })); 

    } catch (e) {
        console.log('viewForComponentName: error', e)
        return null;
    }
}

export function inspectorDefaultsForComponentName (name, items) {
    if (items == null) { return {} }    

    let view = findView(name, items)
    if (view && view.inspector) {
        var inspector = null
        
        try {
            inspector = JSON.parse(view.inspector)
        } catch (e) {
            console.error('inspectorDefaultsForComponent', e)
        }

        var ret = {}

        if (inspector && inspector.properties) {    
            Object.keys(inspector.properties).forEach((key) => {   

                let props = inspector.properties[key]
                let value = props.default ?? props.defaultValue

                if (value) {
                    ret[key] = value
                }

            })
        }

        return ret
    }
    return {}
}

export function assetForKey (key, name, items) {
    // Find the item that wants the asset
    let item = findView(name, items)

    // Get it's path
    let currentViewPath = extractViewPath(item?.path)

    // Find valid asset libraries within that path hierarchy
    let allAssets   = items.filter((item) => item.type == ItemType.assets) 
    let validAssets = allAssets.filter((item) => item.path.startsWith(currentViewPath) == true || item.path.indexOf('/') == -1)  

    for (var assetLibrary of validAssets) {
        let assetSet = Object.values(assetLibrary.assetSets ?? []).find((asset) => asset.name == key)
        if (assetSet) {
            return assetSet
        }
    }

    return null    
}


export const findView = (name, items) => {
    for (const item of items) {

        const itemName = extractViewName(item.name ?? item.title)

        // Match on full or short title
        if (String(item.title).toLowerCase() == String(name).toLowerCase() || 
            String(itemName).toLowerCase() === String(name).toLowerCase() ||
            String(item.path).toLowerCase() === String(name).toLowerCase()) {
            return item
        }
    }
    //console.log('did not find', name);

    return null                    
}
