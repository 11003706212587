import React from 'react';
import { useVariableNode,  Variables } from './Variable.tsx';
import { useRendererContext } from '../RendererContext.tsx';    


export function ForEach({ dataId, functionId , environmentId }) {
    const rendererContext = useRendererContext();
    let data = rendererContext.dataCallback(dataId);
    let f = rendererContext.functionCallback(functionId, environmentId);    
    
    if (data == null) { return "no data" }  
    if (f == null) { return "no function" }

    let content = data.map((item, index) => { 
        rendererContext.setForEachId(index)

        try {
            let ast = f(item, index)
            if (typeof ast === 'function') { ast = ast() }
            if (typeof ast === 'function') { ast = ast() }
            let decode = rendererContext.decodeViewCallback(ast)
            return decode
        } catch (e) {
            return null
        }
    })

    rendererContext.setForEachId(null)

    return content
}
