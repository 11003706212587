import React, { useState, useEffect } from 'react';
import { useActions } from '../Actions.tsx';
import { useStyle, useForegroundStyle } from '../Style.tsx';

export function Rectangle() {
    const actions = useActions()
    const foregroundStyle = useForegroundStyle();

    const currentStyle = useStyle()
    const style = {
        ...currentStyle,
        flexGrow: 1,
        backgroundColor: foregroundStyle.color ?? 'black',
        width: currentStyle.width ?? '-webkit-fill-available', 
    };

    return <div style={style} {...actions}></div>;
}