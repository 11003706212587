import React, { useEffect, useRef } from 'react';
import { YapJSRuntime } from "./JSRuntime"
import { componentsMap, modifiersMap } from './YapUIDecoder';
export function useJSRuntime(id) {
    const ref = useRef()
    if (ref.current == null) {
        ref.current = new YapJSRuntime()
    }
    const lastIdRef = useRef(id)
    const runtime = ref.current
    let reservedComponents = ['Self', 'ComposerAdd']

    if (lastIdRef.current !== id) {
        console.log('reset storage')
        ref.current.resetStorage()
        ref.current.resetState()
        lastIdRef.current = id
    }   

    runtime.registerASTComponents(reservedComponents)    

    return runtime
}